// #region assertString
function _typeof(obj) {
  let _typeof_ = _typeof;
  if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") {
    _typeof_ = (obj) => typeof obj;
  } else {
    _typeof_ = (obj) =>
      obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  }

  return _typeof_(obj);
}

function assertString(input) {
  const isString = typeof input === "string" || input instanceof String;
  if (!isString) {
    let invalidType = _typeof(input);
    if (input === null) invalidType = "null";
    else if (invalidType === "object") invalidType = input.constructor.name;
    throw new TypeError("Expected a string but received a ".concat(invalidType));
  }
}

// #region merge
function merge(obj = {}, defaults = {}) {
  for (const key in defaults) {
    if (obj[key] === undefined) {
      obj[key] = defaults[key];
    }
  }
  return obj;
}

// #region isFQDN
const default_fqdn_options = {
  require_tld: true,
  allow_underscores: false,
  allow_trailing_dot: false,
  allow_numeric_tld: false,
  allow_wildcard: false,
  ignore_max_length: false,
};
function isFQDN(str, options = {}) {
  const _options = merge(options, default_fqdn_options);
  let _str = str;

  assertString(_str);

  if (_options.allow_trailing_dot && _str.endsWith(".")) {
    _str = _str.slice(0, -1);
  }

  if (_options.allow_wildcard && _str.startsWith("*.")) {
    _str = _str.slice(2);
  }

  const parts = _str.split(".");
  const tld = parts[parts.length - 1];

  if (_options.require_tld) {
    if (parts.length < 2) return false;

    const tldPattern = /^([a-z\u00A1-\u00A8\u00AA-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]{2,}|xn[a-z0-9-]{2,})$/i;

    if (!_options.allow_numeric_tld && !tldPattern.test(tld)) {
      return false;
    }

    if (/\s/.test(tld)) return false;
  }

  if (!_options.allow_numeric_tld && /^\d+$/.test(tld)) {
    return false;
  }

  return parts.every((part) => {
    if (part.length > 63 && !_options.ignore_max_length) {
      return false;
    }

    const partPattern = /^[a-z_\u00a1-\uffff0-9-]+$/i;
    if (!partPattern.test(part)) {
      return false;
    }

    if (/[\uff01-\uff5e]/.test(part)) {
      return false;
    }

    if (/^-|-$/.test(part)) {
      return false;
    }

    if (!_options.allow_underscores && /_/.test(part)) {
      return false;
    }

    return true;
  });
}

const IPv4SegmentFormat = "(?:[0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])";
const IPv4AddressFormat = "(".concat(IPv4SegmentFormat, "[.]){3}").concat(IPv4SegmentFormat);
const IPv4AddressRegExp = new RegExp("^".concat(IPv4AddressFormat, "$"));
const IPv6SegmentFormat = "(?:[0-9a-fA-F]{1,4})";
const IPv6AddressRegExp = new RegExp(
  `^(${"(?:".concat(IPv6SegmentFormat, ":){7}(?:").concat(IPv6SegmentFormat, "|:)|")}${"(?:"
    .concat(IPv6SegmentFormat, ":){6}(?:")
    .concat(IPv4AddressFormat, "|:")
    .concat(IPv6SegmentFormat, "|:)|")}${"(?:"
    .concat(IPv6SegmentFormat, ":){5}(?::")
    .concat(IPv4AddressFormat, "|(:")
    .concat(IPv6SegmentFormat, "){1,2}|:)|")}${"(?:"
    .concat(IPv6SegmentFormat, ":){4}(?:(:")
    .concat(IPv6SegmentFormat, "){0,1}:")
    .concat(IPv4AddressFormat, "|(:")
    .concat(IPv6SegmentFormat, "){1,3}|:)|")}${"(?:"
    .concat(IPv6SegmentFormat, ":){3}(?:(:")
    .concat(IPv6SegmentFormat, "){0,2}:")
    .concat(IPv4AddressFormat, "|(:")
    .concat(IPv6SegmentFormat, "){1,4}|:)|")}${"(?:"
    .concat(IPv6SegmentFormat, ":){2}(?:(:")
    .concat(IPv6SegmentFormat, "){0,3}:")
    .concat(IPv4AddressFormat, "|(:")
    .concat(IPv6SegmentFormat, "){1,5}|:)|")}${"(?:"
    .concat(IPv6SegmentFormat, ":){1}(?:(:")
    .concat(IPv6SegmentFormat, "){0,4}:")
    .concat(IPv4AddressFormat, "|(:")
    .concat(IPv6SegmentFormat, "){1,6}|:)|")}${"(?::((?::"
    .concat(IPv6SegmentFormat, "){0,5}:")
    .concat(IPv4AddressFormat, "|(?::")
    .concat(IPv6SegmentFormat, "){1,7}|:))")})(%[0-9a-zA-Z-.:]{1,})?$`
);
function isIP(str, version = "") {
  const _version = String(version);
  assertString(str);
  if (!_version) {
    return isIP(str, 4) || isIP(str, 6);
  }
  if (_version === "4") {
    return IPv4AddressRegExp.test(str);
  }
  if (_version === "6") {
    return IPv6AddressRegExp.test(str);
  }
  return false;
}

// #region isURL
function _slicedToArray(arr, i) {
  return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest();
}
function _nonIterableRest() {
  throw new TypeError(
    "Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."
  );
}
function _unsupportedIterableToArray(o, minLen) {
  if (!o) return;
  if (typeof o === "string") return _arrayLikeToArray(o, minLen);
  let n = Object.prototype.toString.call(o).slice(8, -1);
  if (n === "Object" && o.constructor) n = o.constructor.name;
  if (n === "Map" || n === "Set") return Array.from(o);
  if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen);
}
function _arrayLikeToArray(arr, len) {
  let _len = len;

  if (_len == null || _len > arr.length) {
    _len = arr.length;
  }
  const result = new Array(_len);
  for (let i = 0; i < _len; i++) {
    result[i] = arr[i];
  }
  return result;
}

function _iterableToArrayLimit(iterable, limit) {
  if (iterable == null) return null;

  const iterator = (typeof Symbol !== "undefined" && iterable[Symbol.iterator]) || iterable["@@iterator"];

  if (iterator != null) {
    const result = [];
    let done = false;
    let errorOccurred = false;
    let error;

    const iteratorInstance = iterator.call(iterable);
    try {
      let nextValue = iteratorInstance.next();

      if (limit === 0) {
        if (Object(iteratorInstance) !== iteratorInstance) return;
        done = true;
      } else {
        while (!nextValue.done && result.length !== limit) {
          result.push(nextValue.value);
          nextValue = iteratorInstance.next();
        }
      }
    } catch (err) {
      errorOccurred = true;
      error = err;
    } finally {
      try {
        if (!done && iteratorInstance.return) {
          const returnValue = iteratorInstance.return();
          // eslint-disable-next-line no-unsafe-finally
          if (Object(returnValue) !== returnValue) return;
        }
      } finally {
        // eslint-disable-next-line no-unsafe-finally
        if (errorOccurred) throw error;
      }
    }

    return result;
  }

  return null;
}

function _arrayWithHoles(arr) {
  if (Array.isArray(arr)) return arr;
}

const default_url_options = {
  protocols: ["http", "https", "ftp"],
  require_tld: true,
  require_protocol: false,
  require_host: true,
  require_port: false,
  require_valid_protocol: true,
  allow_underscores: false,
  allow_trailing_dot: false,
  allow_protocol_relative_urls: false,
  allow_fragments: true,
  allow_query_components: true,
  validate_length: true,
};
const wrapped_ipv6 = /^\[([^\]]+)\](?::([0-9]+))?$/;
function isRegExp(obj) {
  return Object.prototype.toString.call(obj) === "[object RegExp]";
}
function checkHost(host, matches) {
  for (let i = 0; i < matches.length; i++) {
    const match = matches[i];
    if (host === match || (isRegExp(match) && match.test(host))) {
      return true;
    }
  }
  return false;
}
export default function isURL(url, options) {
  let _url = url;
  let _options = options;

  assertString(_url);
  if (!_url || /[\s<>]/.test(_url)) {
    return false;
  }
  if (_url.indexOf("mailto:") === 0) {
    return false;
  }
  _options = merge(_options, default_url_options);
  if (_options.validate_length && _url.length >= 2083) {
    return false;
  }
  if (!_options.allow_fragments && _url.includes("#")) {
    return false;
  }
  if (!_options.allow_query_components && (_url.includes("?") || _url.includes("&"))) {
    return false;
  }
  let protocol;
  let auth;
  let host;
  let hostname;
  let port;
  let port_str;
  let split;
  let ipv6;
  split = _url.split("#");
  _url = split.shift();
  split = _url.split("?");
  _url = split.shift();
  split = _url.split("://");
  if (split.length > 1) {
    protocol = split.shift().toLowerCase();
    if (_options.require_valid_protocol && _options.protocols.indexOf(protocol) === -1) {
      return false;
    }
  } else if (_options.require_protocol) {
    return false;
  } else if (_url.slice(0, 2) === "//") {
    if (!_options.allow_protocol_relative_urls) {
      return false;
    }
    split[0] = _url.slice(2);
  }
  _url = split.join("://");
  if (_url === "") {
    return false;
  }
  split = _url.split("/");
  _url = split.shift();
  if (_url === "" && !_options.require_host) {
    return true;
  }
  split = _url.split("@");
  if (split.length > 1) {
    if (_options.disallow_auth) {
      return false;
    }
    if (split[0] === "") {
      return false;
    }
    auth = split.shift();
    if (auth.indexOf(":") >= 0 && auth.split(":").length > 2) {
      return false;
    }
    const _auth$split = auth.split(":");
    const _auth$split2 = _slicedToArray(_auth$split, 2);
    const user = _auth$split2[0];
    const password = _auth$split2[1];
    if (user === "" && password === "") {
      return false;
    }
  }
  hostname = split.join("@");
  port_str = null;
  ipv6 = null;
  const ipv6_match = hostname.match(wrapped_ipv6);
  if (ipv6_match) {
    host = "";
    ipv6 = ipv6_match[1];
    port_str = ipv6_match[2] || null;
  } else {
    split = hostname.split(":");
    host = split.shift();
    if (split.length) {
      port_str = split.join(":");
    }
  }
  if (port_str !== null && port_str.length > 0) {
    port = Number.parseInt(port_str, 10);
    if (!/^[0-9]+$/.test(port_str) || port <= 0 || port > 65535) {
      return false;
    }
  } else if (_options.require_port) {
    return false;
  }
  if (_options.host_whitelist) {
    return checkHost(host, _options.host_whitelist);
  }
  if (host === "" && !_options.require_host) {
    return true;
  }
  if (!isIP(host) && !isFQDN(host, _options) && (!ipv6 || !isIP(ipv6, 6))) {
    return false;
  }
  host = host || ipv6;
  if (_options.host_blacklist && checkHost(host, _options.host_blacklist)) {
    return false;
  }
  return true;
}
