import notificationIcon from "@/assets/image/icon.png";

export default {
  install(Vue) {
    const defaultOptions = {
      icon: notificationIcon,
      badge: notificationIcon,
    };

    Vue.prototype.$Notify = {
      create: (options) => {
        let opts = { ...defaultOptions, ...options };
        let notification = new Notification(options.title, opts);
        notification.onclick = function () {
          window.focus();
        };
        return notification;
      },
      close: (notification) => {
        return notification.close();
      },
    };
  },
};
